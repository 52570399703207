import React,{ useState,useEffect, useRef} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {connect} from 'react-redux';
import swal from 'sweetalert';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MUIDataTable from "mui-datatables";
import {API_URL} from '../../config.json';
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import axios from 'axios';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import AsyncSelect from 'react-select/async';

import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DateFnsUtils from '@date-io/date-fns';



import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';



import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
  } from '@material-ui/pickers';

import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';




import {currentRouteSet} from '../../actions/actions';

import {pathSpliter,checkAuthBranchWare,dateTimeFormat,currentDateTime,dateFormat} from '../../lib/functions'
import moment from 'moment';


import _ from 'lodash';
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const GroupManage = ({location,currentRouteSet,currentRoute,authInfo})=>{
    const classes = useStyles();
 
  useEffect(()=>{
    getExpenses();
    currentRouteSet(pathSpliter(location.pathname,1));
    getExpCode();
    getEmployees()
  },[])

    // Init states start
 
    
    let [expense_id,expense_id_set] = useState(0)
    let [expenses,expensesSet] = useState([])
    let [expenseCart,expenseCartSet] = useState([])
    let [loadToAccounts,loadToAccountsSet] = useState([])
    let [loadFromAccounts,loadFromAccountsSet] = useState([])
    let [employees,employeesSet] = useState([])
    
    let [loadingList,loadingListSet] = useState(false)
    let [loadingSave,loadingSaveSet] = useState(false)

    let [action,action_set] = useState('create');
    let [expense_code,expense_code_set] = useState('');
    let [expense_ind_id,expense_ind_id_set] = useState(null);

    let [narration,narration_set] = useState('');
    let [pay_to,pay_to_set] = useState('');

    

    let [exp_amount,exp_amount_set] = useState('');
    
    
    const [expenseDate, expenseDateSet] = useState(currentDateTime);
    const [selectedAccountFrom, selectedAccountFromSet] = useState(null);
    const [selectedAccountTo, selectedAccountToSet] = useState(null);
    const [selectedEmployee, selectedEmployeeSet] = useState(null);

    let code_ref = useRef(null)
    let acc_from_ref = useRef(null)
    let acc_to_ref = useRef(null)
    let exp_amount_ref = useRef(null)
    let expense_date_ref = useRef(null)
    let narration_ref = useRef(null)


  
  useEffect(()=>{
    getExpenses()
    getloadToAccounts()
    getloadFromAccounts()
  },[expenseDate])

   
    const expenseDateChange = (date) => {
        expenseDateSet(date);
      };


    
    const getExpenses = async ()=>{
        await axios.post(`${API_URL}/api/get-expense-with-details`,{oneDate:expenseDate},{headers:{'auth-token':authInfo.token}}).then(res=>{
        expensesSet(res.data)
      })
    }


    
    const [account_name_from, account_name_from_set] = useState("");
    const [account_name_to, account_name_to_set] = useState("");

    useEffect(()=>{
      getloadToAccounts()
    },[account_name_to])


    useEffect(()=>{
     getloadFromAccounts()
    },[account_name_from])

    const getEmployees = async() => {
      await axios.post(`${API_URL}/api/get-employees`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
        employeesSet(res.data)
      })
  };


    const getloadToAccounts = async() => {
        await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name_to,search:true,type:'indirect_expense'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          loadToAccountsSet(res.data)
        })
    };

    const getloadFromAccounts = async() => {
      await axios.post(`${API_URL}/api/get-accounts-by-search`,{query:account_name_from,search:true,multiType:['cash_in_hand','bank_account']},{headers:{'auth-token':authInfo.token}}).then(res=>{
        loadFromAccountsSet(res.data)
      })
  };


    const getExpCode = async() => {
      return  await axios.post(`${API_URL}/api/get-expense-code`,null,{headers:{'auth-token':authInfo.token}}).then(res=>{
          expense_code_set(res.data)
        })
    };
  
  



    // Init states end
    // Methods script start
    const saveFormAction = async ()=>{

            let totalExpense = _.sumBy(expenseCart,(tran)=>parseFloat(tran.exp_amount))

            if(expenseCart.length == 0){
              swal({
                title:`Expense Cart is Empty.`,
                icon:'warning'
              });
              return false
            }else if(selectedAccountFrom == null){
                swal({
                  title:`Select Payment From  Account`,
                  icon:'warning'
                });
                return false
              }else{
            let payLoad = {
              expense:{
                exp_code:expense_code.trim(),
                creation_date:expenseDate,
                from_acc_id: selectedAccountFrom.acc_id,
                employee_id: selectedEmployee != null ? selectedEmployee.employee_id : 0,
                pay_to,
                exp_total:totalExpense,
                narration,
                exp_id:expense_id,
                action
              },
              expenseDetail:expenseCart
            }
            loadingSaveSet(true);
            await axios.post(`${API_URL}/api/save-expense`,{  
              ...payLoad
            },{headers:{'auth-token': authInfo.token}}).then(res=>{
              loadingSaveSet(false);
             if(res.data.error == false){
               swal({
                 title: res.data.msg,
                 icon:'success'
               })
               expenseDateSet(currentDateTime)
               expenseCartSet([])
               action_set('create')
               narration_set('')
               expense_id_set(0)
               getExpCode()
               getExpenses()
               selectedAccountFromSet(null)
               selectedEmployeeSet(null)
               pay_to_set('')

             }else{
              swal({
                title: res.data.msg,
                icon:'warning'
              })
             }
            });

            }
         
            
          
          
    }


  const removeExpenseTran = (sl)=>{
    let preCopy =  [...expenseCart];
    preCopy.splice(sl, 1);
    expenseCartSet(preCopy)

  }

  const addTOExpense = ()=>{

    if(selectedAccountTo == null){
      swal({
        title:`Select a Account `,
        icon:'warning'
      });
      return false
    }else if((exp_amount == 0 || exp_amount == '')){
      swal({
        title:`Expense Amount  cannot be 0.`,
        icon:'warning'
      });
      return false
    }else{

      let tran = {
        to_acc_id : selectedAccountTo.acc_id,
        acc_name : selectedAccountTo.acc_name,
        exp_amount : exp_amount =='' ? 0 :exp_amount
      }
      if(expense_ind_id == null){
        expenseCartSet([...expenseCart,tran])
      }else{
        let preCopy =  [...expenseCart];
        preCopy[expense_ind_id] = tran
        expenseCartSet(preCopy)

      }


      expense_ind_id_set(null)
      selectedAccountToSet(null)

      exp_amount_set('')
      
     }

    
     

   
    }

    // Methods script end
    const ActionOptions = (props)=>{
      return(<div style={{textAlign:'right'}}> 

{
    authInfo.role !='user'?(
    <>
        <EditIcon style={{cursor:'pointer',fontSize: '28px',color: 'rgb(15, 126, 119)'}} onClick={()=>expenseEdit(props.rowData)}/>
      
        <DeleteIcon style={{cursor:'pointer',color: '#ff0202',fontSize: '28px'}} onClick={()=>expenseDelete(props.rowData)}/>
            
   </>):''
 }
      
      </div>)
    
    }


    const expenseEdit = (row,index)=>{
      let expense =  expenses.filter((expense)=>expense.exp_id == row[0])
      expense = expense[0]
      expenseCartSet(expense.details)
      selectedAccountFromSet({acc_name:expense.acc_name,acc_id:expense.from_acc_id})
      selectedEmployeeSet({employee_name:expense.employee_name,display_text:expense.employee_name,employee_id:expense.employee_id})

      expense_id_set(expense.exp_id)
      action_set('update')
      expense_code_set(expense.exp_code)
      expenseDateSet(expense.creation_date)
      narration_set(expense.narration)
      pay_to_set(expense.pay_to)
     
    }


    const editExpenseTran = (row,sl)=>{
      expense_ind_id_set(sl)
      selectedAccountToSet({acc_name:row.acc_name,acc_id:row.to_acc_id})
      exp_amount_set(row.exp_amount)
    }


    const expenseDelete = async (row)=>{
      swal({
        title:'Are you sure delete this?',
        icon:'warning',
        buttons:true
      }).then(async(yes)=>{
        if(yes){
          let expense =  expenses.filter((expense)=>expense.exp_id == row[0])
          await axios.post(`${API_URL}/api/delete-expense`,{exp_id:expense[0].exp_id},
          {headers:{'auth-token':authInfo.token}}).then(res=>{
                  getExpenses();
                  swal({
                    title: res.data.msg,
                    icon:'success'
                  })
                  
                })
        }else{
          return false
        }
      })
      

          }
   
    const columns = [
      {name: "exp_id",options: { display: 'excluded' }},
      {name:"SL",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
        return ( <p>{parseFloat(tableMeta.rowIndex)+1}</p> ); 
      }
      },headerStyle: {
        textAlign:'left'
      }},
      {name: "exp_code",label: "Expense Code",options: {filter: true,sort: true}},
      {name: "creation_date",label: "Expense Date",options: {filter: true,sort: true,
        customBodyRender:(value,tableMeta)=>{
          return(<p>{ moment(tableMeta.rowData[3]).format(dateFormat) }</p>) 
        }
      }},
      {name: "acc_name",label: "Payment From",options: {filter: true,sort: true}},
      {name: "exp_total",label: "Expense Total",options: {filter: true,sort: true}},
      {name: "pay_to",label: "Pay To",options: {filter: true,sort: true}},
      {name: "narration",label: "Narration",options: {filter: true,sort: true}},
      {name: "user_name",label: "Created By",options: {filter: true,sort: true}},


      {name:"actions",options: {filter: false,sort: false,
        customBodyRender:(value,tableMeta)=>{
          return ( <ActionOptions   value={value} rowIndex={tableMeta.rowIndex}  rowData={tableMeta.rowData} 
             /> ); 
      }
      },headerStyle: {
        textAlign:'right'
      }}
     ];
            
     const options = {
       filterType: 'checkbox',
       selectableRows: 'none',
       display: "excluded"
      }


    return (
    <div className={classes.root}>

     <Paper className={classes.paper} style={{marginTop:'-15px'}}>
    <h2 className={classes.pageEntryLabel}>Expense Entry </h2>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
 


     


<Grid item xs={12} sm={12}>


<Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadToAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountTo}
              onChange={(e,selectedObj)=>{
                selectedAccountToSet(selectedObj)
                if (e.key === "Enter") {
                  exp_amount_ref.current.focus();
                }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={acc_to_ref}
                  onChange={(e)=>{
                    account_name_to_set(e.target.value);
                   
                  }}
                  {...params}
                  label="Paid To Account"
                  variant="outlined"
                
                />
              )}
          />



</Grid>

<br/>
        <Grid item xs={12} sm={12}> 
        <TextField type="number"  
         inputRef={exp_amount_ref}
         onKeyDown={event => {
           if (event.key === "Enter") {
            addTOExpense()
           }
         }} autoComplete='off' className={classes.fullWidth} 
        label="Expense Amount" name="exp_amount" value={exp_amount} 
        variant="outlined" size="small" onChange={(e)=>{

                    exp_amount_set(e.target.value)
                
           
            }} />

<span>Press Enter Key to expense </span>

        </Grid>
        <br/>

        


        <Grid item xs={12} sm={6} style={{marginTop: '20px'}}>
           <Button onClick={()=>addTOExpense()} style={{background: 'rgb(109 113 113)',color: 'white',fontSize: '10px'}}>Add To expense</Button>
        </Grid>


      </Grid>

      <Grid item xs={12} sm={8}>

        <Grid container xs={12} sm={12}>


            <Grid item xs={12} sm={5} style={{float:'left',width: '100%',margin:'5px'}}>
     
     
            <Autocomplete
              autoHighlight={true}
              openOnFocus={true}
              style={{ width: '100%' }}
              options={loadFromAccounts} 
              size="small"
              classes={{
                option: classes.option,
              }}
              
              getOptionLabel={(option) => option.acc_name}
              value={selectedAccountFrom}
              onChange={(e,selectedObj)=>{
                selectedAccountFromSet(selectedObj)
                if (e.key === "Enter") {
                  acc_from_ref.current.focus()
                 }
              }}
              renderInput={(params) => (
                <TextField
                  inputRef={acc_from_ref}
                  onChange={(e)=>{
                    account_name_from_set(e.target.value);
                   
                  }}
                  {...params}
                  label="Pay From Account"
                  variant="outlined"
                
                />
              )}
          />
     
   
            </Grid>


            <Grid item xs={12} sm={3} style={{float:'right',marginTop: '-8px',marginLeft:'5px',margin:'5px'}}> 



            
        <LocalizationProvider dateAdapter={AdapterDateFns} style={{marginTop: '-8px'}}>
        <Stack spacing={3}>
          <DesktopDatePicker
            label="Expense Date" 
            inputFormat={dateTimeFormat}
            inputRef={expense_date_ref}
           
            value={expenseDate}
            onChange={(e)=>expenseDateChange(e)}
            renderInput={(params) => <TextField 
              onKeyDown={event => {
                if (event.key === "Enter") {
                    narration_ref.current.focus()
                }
            }}
            
              {...params} />}
          />
          
        </Stack>
        </LocalizationProvider>



        </Grid>

        


        <Grid item xs={12} sm={3} style={{margin:'5px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
        inputRef={code_ref}
        onKeyDown={event => {
            if (event.key === "Enter") {
              expense_date_ref.current.focus()
            }
          }} 
        label="Expense Code" name="expense_code" value={expense_code} 
        variant="outlined" size="small" onChange={(e)=>expense_code_set(e.target.value)} />
        </Grid>





        <Grid item xs={12} sm={3} style={{margin:'5px'}}>
     
     
     <Autocomplete
     style={{marginTop:'5px'}}
       autoHighlight={true}
       openOnFocus={true}
       style={{ width: '100%' }}
       options={employees} 
       size="small"
       classes={{
         option: classes.option,
       }}
       
       getOptionLabel={(option) => option.display_text}
       value={selectedEmployee}
       onChange={(e,selectedObj)=>{
         selectedEmployeeSet(selectedObj)
         if(selectedObj != null){
          pay_to_set(selectedObj.display_text)
         }else{
          pay_to_set('')
         }
        
       }}
       renderInput={(params) => (
         <TextField
          
           {...params}
           label="Select Employee"
           variant="outlined"
         
         />
       )}
   />


     </Grid>

     <Grid item xs={12} sm={3} style={{margin:'5px'}}> 
        <TextField  autoComplete='off' className={classes.fullWidth} 
        label="Pay To" name="pay_to" value={pay_to} 
        variant="outlined" size="small" onChange={(e)=>pay_to_set(e.target.value)} />
        </Grid>


        
        </Grid>
     
     




        <Grid item xs={12} sm={12}>
        <TableContainer >
      <Table className={classes.table} size="small" aria-label="a dense table" style={{marginTop: '20px'}}>
        <TableHead>
              <TableRow>
              <TableCell align="left"   style={{width:'1%'}}>SL</TableCell>
              <TableCell align="left"   style={{width:'20%'}}>Account</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Expense Amount</TableCell>
              <TableCell align="right"  style={{width:'10%'}}>Actions</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
              {
                expenseCart.map((acc,sl)=>(<>
                   <TableRow key={sl} style={{background: sl == expense_ind_id ? '#dedede':'',  transition: '1s'}}>
                  <TableCell align="left">{sl+parseFloat(1)}</TableCell>
                  <TableCell align="left">{acc.acc_name}</TableCell>
                  <TableCell align="right">{format(parseFloat(acc.exp_amount).toFixed(2))}</TableCell>
                  <TableCell align="right">
                  <EditIcon style={{cursor:'pointer',color:'#2e482e',marginLeft:'2px'}} onClick={()=>{editExpenseTran(acc,sl)}}></ EditIcon>
                  <span style={{width:'2px'}}> </span>
                  <RemoveCircleIcon style={{cursor:'pointer',color:'#582727',marginLeft:'2px'}} onClick={()=>{removeExpenseTran(sl)}}></ RemoveCircleIcon>
                  </TableCell>
              
                  </TableRow>
                </>))
              }

              {
                expenseCart.length > 0 ? (<>
                <TableRow style={{background: 'rgb(249 243 243)',transition: '1s'}}>
                    <TableCell align="right" colSpan={2} style={{fontWeight:'bold'}}>Total Expense Amount : </TableCell>
                    <TableCell align="right" style={{fontWeight:'bold'}}> {format(parseFloat(_.sumBy(expenseCart,(tran)=>parseFloat(tran.exp_amount))).toFixed(2))}</TableCell>
                    <TableCell align="right">----------</TableCell>
                 </TableRow>
                </>):''
                 
              }
             
         


     
        </TableBody>
      </Table>
    </TableContainer>


    <TableContainer  style={{float:'right'}}>
      <Table className={classes.table} size="small" aria-label="a dense table">
     
          <TableRow > 
             <TableCell style={{width: '325px'}}><TextareaAutosize  
             ref={narration_ref}
            
               
               onChange={(e)=>{narration_set(e.target.value)}} value={narration}  name="narration" style={{float:'left',marginTop:'20px',width: '325px'}} aria-label="Narration..." rowsMin={3} placeholder="Narration..." />
             </TableCell>

             <TableCell colSpan={2}></TableCell>

           
          
          </TableRow>
        
      </Table>
    </TableContainer>


        </Grid>



        </Grid>


      


     



        

      
  
      </Grid>


  
         <Grid item xs={12}>
  <Button style={{marginTop: '25px'}}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        style={{color: 'white',
          backgroundColor: '#115f5f'}}
        disabled={loadingSave}
        startIcon={<SaveIcon />}
        onClick={saveFormAction}
      >
        Save
      </Button>
  </Grid>         


     </Paper>

     {
      loadingList==true?(<b>Loading...</b>):(
        <Paper className={classes.paper} style={{marginTop:'20px'}}>
        <MUIDataTable
      title={"Expense Entry List"}
      data={expenses}
      columns={columns}
      options={options}
      adjustForCheckbox={false} 
      displaySelectAll={false}
      />
      </Paper>
      )
      
     }

     
    </div>
        
    );
}


 const options = {
   filterType: 'checkbox',
 };
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },

root: {},
'& .MuiTextField-root': {
  margin: theme.spacing(1),
  width: '25ch',
 },
  paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
  },
  pageEntryLabel:{
      color: '#7754cc',
      margin: '0px',
      padding: '0px',
      marginTop: '-11px',
      textAlign: 'left',
      marginLeft: '0px',
      marginBottom: '5px'
  },
  fullWidth:{
      width:'100%'
  },
  option: {
      fontSize: 15,
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    plusLinkDiv:{
        position:'relative'  
  },
  plusLink:{
    margin: 0,
    padding: 0,
    marginTop: '-21px',
    fontSize: '29px',
    height: '21px',
    textAlign: 'right',
    position: 'absolute',
    right: 0,
    color: '#3e8d54'
  }
}));

const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(GroupManage);