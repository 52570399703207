import React,{useState,useEffect,useRef} from 'react';
import {connect} from 'react-redux';

import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,_p,sleep,dateTimeFormat,dateFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import { useHistory } from "react-router-dom";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DdatateIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment';
import _ from 'lodash';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import InstitutionProfile from '../commons/institution_profile'
import '../commons/commons.css'

import Stack from '@mui/material/Stack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';


import swal from 'sweetalert';
import commaNumber from 'comma-number';
import { TableFooter } from 'semantic-ui-react';
let format = commaNumber.bindWith(',', '.')
const SalesRecord = ({location,currentRoute,currentRouteSet,authInfo})=>{
    const classes = useStyles();
    const history = useHistory();

    useEffect(()=>{
        currentRouteSet(pathSpliter(location.pathname,1));
        getBalances()
    },[]);

    let [report,reportSet] = useState([]);

    let [filterTypes,filterTypesSet] = useState([{filterType:'Lifetime'},{filterType:'Date Wise'}
  ]);


  
    let [selectedFilterType,selectedFilterTypeSet] = useState({filterType:'Lifetime'})

    let [reportLoading,reportLoadingSet] = useState(false);

    let [balances,balancesSet] = useState({
      salesBalance :0,
      salesReturnBalance :0,
      purchaseBalance :0,
      purchaseReturnBalance :0,
      directIncomeBalance :0,
      directExpenseBalance :0,
      indirectIncomeBalance :0,
      indirectExpenseBalance :0,
      creditorBalance :0,
      advanceDebtorBalance :0,
      capitalBalance :0,
      diffOpeningBalance :0,
      loanBalance :0,
      cashBalance :0,
      bankBalance :0,
      debtorBalance :0,
      advanceCreditorBalance :0,
      fixedAssestsBalance :0,
      taxBalance :0,
      serviceBalance:0,
      serviceExpenseBalance:0,
    })


    let [byDateTime,byDateTimeSet] = useState({
      dateTimeFrom: currentDateStartTime(),
      dateTimeTo:currentDateEndTime()
})

    

    let [print,printSet] = useState(false);


    let getBalances = async ()=>{

     
        reportLoadingSet(true)
        let url =  `${API_URL}/api/`;

        let payLoad = {
          type:'head_total',
          selectedFilterType: selectedFilterType != null?selectedFilterType.filterType:null,
        }

        if(selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )){
          payLoad.fromDate = byDateTime.dateTimeFrom
          payLoad.toDate = byDateTime.dateTimeTo
        }


        // Liabilities start

        await axios.post(`${url}get-trial-balance`,payLoad,{headers:{'auth-token':authInfo.token}}).then(res=>{
          let blc = res.data;
          balances.salesBalance =  blc.salesBalance
          balances.salesReturnBalance =  blc.salesReturnBalance
          balances.purchaseBalance =  blc.purchaseBalance
          balances.purchaseReturnBalance =  blc.purchaseReturnBalance
          balances.directIncomeBalance =  blc.directIncomeBalance
          balances.directExpenseBalance =  blc.directExpenseBalance
          balances.indirectIncomeBalance =  blc.indirectIncomeBalance
          balances.indirectExpenseBalance =  blc.indirectExpenseBalance

          balances.creditorBalance = res.data.creditorBalance;
          balances.capitalBalance = res.data.capitalBalance;
          balances.diffOpeningBalance = res.data.diffOpeningBalance;
          balances.loanBalance = res.data.loanBalance;
          balances.advanceDebtorBalance = res.data.advanceDebtorBalance;
          // Assets
          balances.cashBalance = res.data.cashBalance;
          balances.bankBalance = res.data.bankBalance;
          balances.debtorBalance = res.data.debtorBalance;
          balances.fixedAssestsBalance = res.data.fixedAssestsBalance;
          balances.taxBalance = res.data.taxBalance;

          balances.serviceBalance = res.data.serviceBalance;
          balances.serviceExpenseBalance = res.data.serviceExpenseBalance;
          balances.receiveableBranchAmount = res.data.receiveableBranchAmount;
          balances.advanceCreditorBalance = res.data.advanceCreditorBalance;

          
        })


            
        

      

       
        // Assets End
        printSet(true)
        reportLoadingSet(false)

    }




    let ReportDom = React.forwardRef( (props,ref) =>{
        return(
          <div ref={ref} >
                        <InstitutionProfile />

<div style={{clear:'both'}}></div>
<Paper style={{paddingLeft:'10px',paddingRight:'10px'}} className="print-source">
<p style={{width:'100%',textAlign:'center',fontWeight:'bold',
textAlign: 'center',
fontSize: '24px',
margin: '0',
padding: '0',
fontStyle: 'italic',
fontFamily: 'fantasy',
color: '#1c721c'}}>Trial Balance</p>
<div style={{float:'left',width:'50%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
</div>

<div style={{width:'10%'}}></div>

<div style={{float:'right',width:'40%',textAlign:'left',margin:0,padding:0,fontSize:'16px'}}>
{/* <p style={{margin:'0px'}}>Statement  :  { moment(byDate.dateFrom).format(dateFormat)  } - { moment(byDate.dateTo).format(dateFormat) }</p> */}
</div>
 


</Paper> 

{
    
    <Paper className={classes.paper}  style={{paddingLeft:'10px',paddingRight:'10px',marginTop:'1px',marginBottom:'1px'}}>
    
    <Grid container spacing={3}>
       


          <Grid item  xs={12}   sm={12} >
          <TableContainer >
            <Table className={classes.table} size="small" aria-label="a dense table" className="report-dom">
                <TableHead>
                <TableRow> 
                    <TableCell align="left"  width="30%" style={{fontSize: '14px',fontWeight: 'bold'}}>PARTICULARS ACCOUNT HEAD</TableCell>
                    <TableCell align="right" width="30%" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>Debit</TableCell> 
                    <TableCell align="right" width="30%" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>Credit</TableCell> 
                </TableRow>
                </TableHead>
                <TableBody>

                       <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Cash In Hand</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.cashBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Bank Accounts Balance</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.bankBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Debtors/ Customers Balance</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.debtorBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>


                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Receivable From Others Branch </TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.receiveableBranchAmount).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Advance Payment to Creditors </TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.advanceCreditorBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>




                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Creditors/ Suppliers Balance</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.creditorBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Purchase Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.purchaseBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Service Expense Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.serviceExpenseBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Purchase Return Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.purchaseReturnBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Sales Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.salesBalance).toFixed(2))}</TableCell>
                        </TableRow>


                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Service Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.serviceBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Sales Return Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.salesReturnBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

         
                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Capital Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.capitalBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Direct Expense Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.directExpenseBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Indirect Expense Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.indirectExpenseBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Indirect Income Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.indirectIncomeBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Direct Income Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.directIncomeBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        
                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Loans Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.loanBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Advance Received From Debtors</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.advanceDebtorBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Fixed Assets Account</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.fixedAssestsBalance).toFixed(2))}</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Difference In Opening	</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.diffOpeningBalance).toFixed(2))}</TableCell>
                        </TableRow>

                        <TableRow> 
                        <TableCell align="left" style={{fontSize: '14px',fontWeight: 'bold'}}>Vat Account	</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>------</TableCell>
                        <TableCell  align="right" style={{fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{format(parseFloat(balances.taxBalance).toFixed(2))}</TableCell>
                        </TableRow>
 
                        
                        <TableRow > 
                        <TableCell align="left" style={{background:'#cecfce',fontSize: '14px',fontWeight: 'bold'}}>Grand Total : </TableCell>
                        <TableCell  align="right" style={{background:'#b1e59c',fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{authInfo.currency} {
                         format(parseFloat(
                          parseFloat(balances.cashBalance) + 
                          parseFloat(balances.bankBalance) + parseFloat(balances.debtorBalance) + 
                          parseFloat(balances.purchaseBalance) + parseFloat(balances.salesReturnBalance) + 
                          parseFloat(balances.directExpenseBalance)  + parseFloat(balances.indirectExpenseBalance) 
                          + parseFloat(balances.fixedAssestsBalance)  + parseFloat(balances.serviceExpenseBalance)  
                          + parseFloat(balances.receiveableBranchAmount)
                          + parseFloat(balances.advanceCreditorBalance)
                         ).toFixed(2))
                        }</TableCell>
                        <TableCell  align="right" style={{background:'#eb6d6d',fontSize: '14px',fontWeight: 'bold',lineHeight:'2'}}>{authInfo.currency} {
                           format(parseFloat(
                            parseFloat(balances.creditorBalance) +  parseFloat(balances.purchaseReturnBalance) +
                            parseFloat(balances.salesBalance) +  parseFloat(balances.serviceBalance) +
                            parseFloat(balances.capitalBalance) + parseFloat(balances.indirectIncomeBalance) +
                            parseFloat(balances.directIncomeBalance) + parseFloat(balances.loanBalance) + parseFloat(balances.diffOpeningBalance) 
                            + parseFloat(balances.advanceDebtorBalance)
                            
                           ).toFixed(2))
                        }</TableCell>
                        </TableRow>

                    </TableBody>
                    </Table>
                </TableContainer>
          </Grid>
          
          </Grid>
    
        </Paper>
  
    
}
    


      
     
<div style={{clear: 'both',height:'10px'}}></div>
<div  className="print-source" style={{marginTop:'70px'}}>
                                <p style={{float:'left',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Received By</p>
                           
                                 <p style={{float:'right',marginRight:'10px',borderTop:'1px solid #222',width:'150px',textAlign:'center'}}>Authorized By</p>
                      </div>
          </div>
        )
    })




    const reportRef = useRef();

   

      return(
          <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'0px'}}>
<h4 style={{ textAlign: 'left',margin: "0px 0px  7px 0px",padding: '0px',color: '#222',textAlign: 'center',
    fontSize: '24px',
    margin: '0',
    padding: '0',
    fontStyle: 'italic',
    fontFamily: 'fantasy',
    color: '#1c721c',marginBottom:'20px'}}>Trial Balance { reportLoading == true ? ' --- Loading...':'' }</h4>



<Paper className={classes.paper} style={{marginTop:'-10px',marginBottom:'0px'}}>
            <Grid container>
                <Grid item  xs={12}  sm={2}> 
          <Autocomplete 
          size="small"
          autoHighlight
              openOnFocus={true}
              style={{width:'100%',height:'20px'}}
              options={filterTypes} 
              value={selectedFilterType}
              getOptionLabel={(option) => option.filterType}
              onChange={(event,selectedObj)=>{
                selectedFilterTypeSet(selectedObj) 
              }}
              renderInput={(params) => <TextField 
               
                {...params} 
                label="Filter Type" 
                variant="outlined"
                />} 
          />

          </Grid>
              
          <div  style={{width: '20px'}} > </div>


          <Grid item  xs={12}  sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )?'block':'none'}} > 
          
          



          <LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="From Date" 
        inputFormat={dateTimeFormat}
        value={byDateTime.dateTimeFrom}
        onChange={(datet)=>{
          byDateTimeSet({...byDateTime,dateTimeFrom:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>


        </Grid>
        <div  style={{width: '20px'}} > </div>


        <Grid item  xs={12}  sm={2} style={{display: selectedFilterType!=null && (selectedFilterType.filterType=='Date Wise' )?'block':'none'}}  > 


        

<LocalizationProvider dateAdapter={AdapterDateFns}>
    <Stack spacing={3}>
      <DesktopDatePicker
        label="To Date" 
        inputFormat={dateTimeFormat}
        value={byDateTime.dateTimeTo}
        onChange={(datet)=>{
          byDateTimeSet({...byDateTime,dateTimeTo:datet})
        }}
        renderInput={(params) => <TextField {...params} />}
      />
      
    </Stack>
  </LocalizationProvider>

        </Grid>

        <div  style={{width: '20px'}} > </div>

        <Grid item  xs={12}   sm={1} >
      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'10px'}} 
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SearchIcon/>}
                          onClick={getBalances}
                          disabled={reportLoading?true:false}
                      >
                      Report 
                    </Button>
      </Grid>
               
            </Grid>
        </Paper>


        <br/>
        <br/>
        </Paper>
        {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-17px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }
      
            <ReportDom ref={reportRef} /> 




          </div>
      )
}



const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
        color: 'white',
        fontSize:'10px',
        backgroundColor: '#115f5f'
      },
    
    root: {
      flexGrow: 1,
    },
    table:{
      fontSize:'20px'
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(SalesRecord); 
